<template>
	<nav class="student__header secondary-contrast-background">
		<v-container>
			<v-row class="centered">
				<v-col cols="12">
					<div class="student__header--wrapper">
						<router-link to="/student/dashboard">
							<figure><img id="logo" :src="logoImage" /></figure>
						</router-link>

						<ul class="student__header--list">
							<li>
								<router-link
									class="contrast-text"
									to="/student/dashboard"
									>DASHBOARD</router-link
								>
							</li>
							<li>
								<router-link
									class="contrast-text"
									to="/student/courses"
									>COURSES</router-link
								>
							</li>
							<li>
								<router-link
									class="contrast-text"
									to="/student/certificates"
									>CERTIFICATES</router-link
								>
							</li>
							<li>
								<DropdownUser></DropdownUser>
							</li>
						</ul>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</nav>
</template>

<script>
import { mapState } from "vuex";
import DropdownUser from "@/components/DropdownUser.vue";

export default {
	name: "StudentHeader",
	data() {
		return {};
	},

	components: {
		DropdownUser
	},

	computed: {
		...mapState({
			userFullName: state => state.user.name,
			userEmail: state => state.user.email
		}),

		logoImage() {
			return this.$store.state.organization.logo?.file?.public_url;
		},

		userInitials(speaker) {
			const name = this.userFullName?.split(" ");
			return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
		}
	},

	methods: {
		userLogout() {
			this.$store.dispatch("logout");
		}
	}
};
</script>

<style></style>
